<template>
  <div>
    <div class="page-heading">
      <h2>Add Community Moderator</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Full Name</label>
                <input type="text" required placeholder="Full Name" v-model="user.name" class="form-control" id="form-option-name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Email</label>
                <input type="text" required placeholder="Email" v-model="user.email" class="form-control" id="form-option-email">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone Number</label>
                <input type="text" required placeholder="Phone Number" v-model="user.phone_number" class="form-control" id="form-option-phone_number">
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <button class="btn btn-secondary btn-sm"><i class="lni lni-save mr-2"></i>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {}
    }
  },

  methods: {
    submit() {
      this.$loader.start()
      this.$axios.post('/api/v1/moderator/moderators', this.user).then(response => {
        this.$loader.stop()
        this.$router.push({ name: 'moderator.users.show', params: { id: response.data.id }})
      }).catch(error => {
        this.$errors.handle(error)
      })
    }
  }
}
</script>