<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h1>Add New User</h1>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <select v-model="role" class="form-control">
            <option value="1">Trader</option>
            <option value="2">Moderator</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body" v-if="role == 1">
        <add-trader />
      </div>
      <div class="card-body" v-if="role == 2">
        <add-moderator />
      </div>
    </div>
  </div>
</template>

<script>
import AddTrader from './components/AddTrader.vue'
import AddModerator from './components/AddModerator.vue'

export default {
  components: {
    AddTrader, AddModerator
  },

  data() {
    return {
      role: 1
    }
  }
}
</script>